import React, { Component } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import Collapse from "react-bootstrap/Collapse";
import globalData from "../config.json";
import Loading from "./Loading";
import axios from "axios";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { Helmet } from "react-helmet"; // Import Helmet for SEO



class Review extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      reviews: [],
      count: "",
      mode: "",
      activeIndex: [],
      data: "",
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  loadScript() {
    window.scrollTo(0, 0);

    let scripts = [{ src: globalData.appUrl + "front/js/app-html.js" }];

    //Append the script element on each iteration

    scripts.forEach((item) => {
      const script = document.createElement("script");
      script.id = "myScript";
      script.src = item.src;

      document.body.appendChild(script);
    });

    const script = document.getElementById("myScript");
    if (script === undefined) {
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration

      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });
    } else {
      script.parentNode.removeChild(script);
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration
      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });
    }
  }
  componentDidMount() {
    /* window.scrollTo(0, 0);
    let scripts = [
      {
        src: "https://dev.twynhamstours.co.uk/twynhams-tours/public/front/js/app-html.js",
      },
    ];

    scripts.forEach((item) => {
      const script = document.createElement("script");
      script.id = "myScript";
      script.src = item.src;
      script.async = true;
      document.body.appendChild(script);
    }); */
    // window.addEventListener("scroll", this.handleScroll);
    document.title = 'Review';
    ReactGA.initialize(globalData.googleAnalyticKey);
    ReactGA.pageview(window.location.pathname);
    ReactPixel.init(globalData.metaFacebookKey);
    ReactPixel.pageView(window.location.pathname);
    this.loadScript();
    this.getReviews();
  }

  handleScroll() {
    // THEME.header.stickyHeaderInit(".hdr-content-sticky");
  }

  handleClick(e) {
    const reducedArr = this.state.activeIndex;
    let check = this.state.activeIndex.some((item) => e === item);

    if (check) {
      this.setState({
        activeIndex: reducedArr.filter(
          (item, index) => reducedArr.indexOf(e) !== index
        ),
      });
    } else {
      this.setState({
        activeIndex: this.state.activeIndex.concat(e),
      });
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    const script = document.getElementById("myScript");
    script.parentNode.removeChild(script);
  }

  getReviews() {
    axios.get(globalData.apiUrl + "/api/reviews").then((response) => {
      this.setState({
        reviews: [...response.data.data],
        count: response.data.count,
        data: response.data.cms,
        loading: false,
      });

      this.setState({ mode: response.data.data.length % 4 });
      this.setState({ count: response.data.data.length - this.state.mode });
    });
  }
  testCart = () => {
    // alert("in ");
    this.props.updateCartCount(55);
  };
  render() {
    let dynamicClass = 4;
    let show = "";

    return (
      <>
       {this.state.data  ?
        <Helmet>
          <title>{this.state.data.meta_title}</title>
          <meta
            name="title"
            content={this.state.data.meta_title} />
          <meta
            name="description"
            content={this.state.data.meta_description}
          />
          <meta name="keywords" content={this.state.data.meta_keywords} />
        </Helmet>
        : ""
  }
        <div
          id="main-wrapper"
          className="tour-review bg-dark for-padding-review"
        >
          <div className="container-fluid">
            {this.state.loading ? <Loading /> : ""}
            <div className="row text-center">
              <div className="col-lg">
                <h2 className="text-center text-white">Guest Reviews</h2>
                {/* <button name="test" onClick={this.testCart}> TEst Click </button> */}
                <h3 className="text-white">                
                  Don't just take our word for it, here are a few examples of
                  why we are a market
                  <br /> leading Motorsport tour operator!
                </h3>
                <h3 className="text-white">
                  To read more of our 5* reviews visit our independent reviews
                  page{" "}
                  <a
                    href="https://www.facebook.com/twynhamstours/reviews/"
                    target="_blank"
                    className="click-link"
                  >
                    here.
                  </a>
                </h3>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg">
                <div className="py-md-5 px-0">
                  <div className="row d-flex justify-content-center">
                    {this.state.reviews.map((rev, index) => {
                      dynamicClass =
                        this.state.count > index
                          ? "3"
                          : this.state.mode == 1
                          ? "12"
                          : this.state.mode == 2
                          ? "6"
                          : this.state.mode == 3
                          ? "4"
                          : "";

                      return (
                        <div
                          className={`col-md-${dynamicClass} mb-3`}
                          key={rev.id}
                        >
                          <div className="readmore-rendered">
                            <div
                              className={
                                "read-more collapse " +
                                (this.state.activeIndex.some(
                                  (item) => rev.id === item
                                )
                                  ? "show"
                                  : " ")
                              }
                              id={`a${rev.id}`}
                            >
                              <i className="icon-quotes font-23"></i>
                              <h4 className="text-white mb-1">{rev.title}</h4>
                              {ReactHtmlParser(rev.body)}
                            </div>
                            <a
                              className="read-link"
                              style={{ cursor: "pointer" }}
                              data-toggle="collapse"
                              onClick={() => this.handleClick(rev.id)}
                            >
                              Read{" "}
                              {this.state.activeIndex.some(
                                (item) => rev.id === item
                              )
                                ? "less"
                                : "more"}
                            </a>
                            <h5 className="mb-0 text-white">
                              {rev.author} -{" "}
                              <label className="text-white">
                                {rev.date_of_review}
                              </label>
                            </h5>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

//export default Review;

const mapStateToProps = (state) => {
  return {
    cartCount: state.cart_count,
    userData: state.user_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCartCount: (count) => dispatch({ type: "CART_COUNT", value: count }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Review);
