import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import globalData from "../config.json";
import Loading from "./Loading";
import axios from "axios";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet"; // Import Helmet for SEO

class AboutUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      dataImages: [],
      errors: [],
      loading: true,
    };
  }

  componentDidMount() {
    document.title = "About Us";
    ReactGA.initialize(globalData.googleAnalyticKey);
    ReactGA.pageview(window.location.pathname);
    ReactPixel.init(globalData.metaFacebookKey);
    ReactPixel.pageView(window.location.pathname);

    this.loadScript();
    this.getAboutus();
  }

  componentWillUnmount() {
    const script = document.getElementById("myScript");
    if (script) {
      script.parentNode.removeChild(script);
    }
  }

  loadScript = () => {
    const existingScript = document.getElementById("myScript");
    if (existingScript) {
      existingScript.parentNode.removeChild(existingScript);
    }

    const script = document.createElement("script");
    script.id = "myScript";
    script.src = globalData.apiUrl + "/front/js/app-html.js";
    script.async = true;
    document.body.appendChild(script);
  };

  getAboutus = () => {
    axios
      .post(globalData.apiUrl + "/api/getCmsData", { slug: "about-us" })
      .then((response) => {
        this.setState({
          data: response.data.data,
          dataImages: [...response.data.dataImages],
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ errors: error, loading: false });
      });
  };

  render() {
    const { data, dataImages, loading } = this.state;

    return (
      <div id="main-wrapper" className="tour-aboutus bg-dark">
        {loading && <Loading />}
        <Helmet>
          <title>{data.meta_title}</title>
          <meta
            name="title"
            content={data.meta_title}
          />
          <meta
            name="description"
            content={data.meta_description}
          />
          <meta name="keywords" content={data.meta_keywords} />
        </Helmet>
        {dataImages.length > 0 && (
          <div className="slideshow">
            {dataImages.map((img) => (
              <img key={img.id} src={img.imageUrl} alt="aboutimage" />
            ))}
          </div>
        )}
        {data && (
          <div className="page-content">
            <div className="container pb-4 text-light">
              <h1 className="text-light">{data.title}</h1>
              {ReactHtmlParser(data.html)}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default AboutUs;
